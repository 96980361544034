.Wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s ease-out;
  background-color: rgba(1, 22, 45, 0.2);
  z-index: 20;
  opacity: 0;
}

.Container {
  width: fit-content;
  height: fit-content;
  transition: opacity 0.15s ease-out, transform 0.15s ease-out;
  pointer-events: all;
  border: 1px solid var(--ter-black);
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.15);
  border-radius: 1.6rem;
}

@media screen and (max-width: 650px) {
  .Container {
    width: 80%;
  }
}
@media screen and (max-width: 450px) {
  .Container {
    box-shadow: none;
    width: 95%;
  }
}
