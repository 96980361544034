.Wrapper {
  /* margin-top: 7rem; */
  width: 100%;
  overflow: hidden;
}

.SocialMediaIcons {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1.5rem;
  margin-top: 1.5rem;
  width: 100%;
  column-gap: 10rem;
}

.SocialMediaIconsMobile {
  display: flex;
  flex-direction: flex-start;
  /* justify-content: start; */
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
}

.SocialMediaTextMobile {
  font-weight: 400;
  font-size: var(--font-14);
  line-height: 2rem;
  color: var(--pure-white);
  opacity: 0.65;
  margin-left: 1rem;
}

.FooterWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-bg-dark);
  padding: 5.4rem max(4rem, calc((100% - 114rem) / 2));
}


.TopMostSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-bg-dark);
  width: 100%;
}

.FooterMidSection {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 4rem;
  margin-top: 3.35rem;
  margin-bottom: 3.2rem;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3.5rem;
  border-top: 0.1rem;
  border-bottom: 0.1rem;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.65);
  border-left: none;
  border-right: none;
  color: var(--pure-white);
}

.FooterMidSectionItem {
  display: flex;
  flex-direction: column;
  gap: 1.9rem;
}

.FooterMidSectionItemHeading {
  font-size: var(--font-16);
  line-height: 1.7rem;
  color: var(--pure-white);
  font-weight: 700;
  white-space: nowrap;
}

.FooterMidSectionItemLink {
  text-decoration: none;
  color: var(--pure-white);
  font-size: var(--font-15);
  line-height: 1.7rem;
  font-weight: 400;
  opacity: 0.65;
  cursor: pointer;
  /* white-space: nowrap; */
}

.FooterMidSectionItemLink2 {
  text-decoration: none;
  color: var(--pure-white);
  font-size: var(--font-15);
  line-height: 1.7rem;
  font-weight: 400;
  opacity: 0.65;
  cursor: pointer;
}

.IsoImage {
  width: 11rem;
  height: 11rem;
  margin-top: -15rem;
}

.CareersLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: row;
}



.BottomFooterSection {
  font-weight: 400;
  font-size: var(--font-15);
  line-height: 1.7rem;
  color: #ffffff;
  opacity: 0.65;
  margin-top: 1rem;
}

.HiringButton {
  padding: 0.3rem 0.996875rem 0.41875rem 0.8rem;
  background: var(--lig-blue);
  border-radius: 0.2rem;
  font-weight: 600;
  font-size: var(--font-11);
  line-height: 1.3rem;
  color: var(--pure-white);
  display: flex;
  align-items: center;
  white-space: nowrap;
}


@media only screen and (min-width: 1024px) {
  .FooterMidSection {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 7.15rem;
  }

  .FooterMidSectionItemLink {
    text-decoration: none;
    color: var(--pure-white);
    font-size: var(--font-15);
    line-height: 1.7rem;
    font-weight: 400;
    opacity: 0.65;
    cursor: pointer;
    white-space: nowrap;
  }

  .FooterMidSectionItemLink2 {
    text-decoration: none;
    color: var(--pure-white);
    font-size: var(--font-15);
    line-height: 1.7rem;
    font-weight: 400;
    opacity: 0.65;
    cursor: pointer;
  }

  .TopMostSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blue-bg-dark);
    width: 100%;
  }

  .SocialMediaIcons {
    display: flex;
    gap: 2rem;
  }

}

@media only screen and (max-width: 900px) {
  .IsoImage {
    margin-top: 0;
  }

}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
  .FooterMidSection {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 7.15rem;
  }

  .TopMostSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blue-bg-dark);
    width: 100%;
  }

  .SocialMediaIcons {
    display: flex;
    gap: 2rem;
  }
}


@media only screen and (min-width: 650px) and (max-width: 900px) {
  .FooterMidSection {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 7.15rem;
  }

  .TopMostSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blue-bg-dark);
    width: 100%;
  }

  .SocialMediaIcons {
    display: flex;
    gap: 2rem;
  }
}

@media only screen and (min-width: 500px) and (max-width: 650px) {
  .TopMostSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blue-bg-dark);
    width: 100%;
  }

  .SocialMediaIcons {
    display: flex;
    gap: 2rem;
  }
}