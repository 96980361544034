.Wrapper {
  padding: 4rem;
  background-color: var(--pure-white);
  border-radius: 14px;

  width: 85rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}
.Heading {
  font-size: var(--font-28);
}
.FormWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.FormHeader {
  font-weight: 700;
  font-size: var(--font-32);
  color: var(--font-black);
  margin: 1rem 1rem 2.4rem 1rem;
}
.DisabledInput {
  cursor: not-allowed;
}
.FormName {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.Input {
  display: grid;
  /* padding: 1rem; */
}
.PhoneInputComponent {
  padding: 0;
  height: 5rem !important;
  margin-bottom: 2rem;
}
.PhoneInputComponent span {
  font-size: var(--font-16);
}
.PhoneInputComponent input {
  height: 5rem !important;
}
.FormHeading {
  font-size: var(--font-16);
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.Mandatory::after {
  content: " *";
  color: var(--sec-red);
}
.FormInputName {
  width: 30rem;
  height: 5rem;
  background: var(--pent-grey);
  border-radius: 0.6rem;
  border: none;
  padding-left: 1rem;
  font-size: var(--font-18);
}
.TermsLink,
.TermsLink:hover {
  color: var(--sec-blue);
  text-decoration: underline;
}

.FormInputName:focus {
  outline: none;
  border: 0.2rem solid var(--sec-blue);
  background-color: var(--pure-white);
}

.Input p span:last-child {
  font-size: var(--font-12);
  font-weight: 500;
}
.CodeInput {
  border-radius: 0.6rem !important;
  background: var(--pent-grey);
  border-radius: 0.6rem;
  border: none;
  font-size: var(--font-16);
  padding-left: 1rem;
  font-size: var(--font-18);
  height: 5rem !important;
}
.CodeInput:focus {
  outline: none;
  border: 0.2rem solid var(--sec-blue);
  background-color: var(--pure-white);
}

.SendBtn {
  height: 5rem;
  justify-content: center;
  background: var(--sec-blue);
  border-radius: 0.6rem;
  color: var(--pure-white);
  margin: 0;
  width: 100%;
  border: none;
  font-size: var(--font-18);
  font-weight: 500;
}

.ButtonWrapper {
  width: 100%;
  padding: 0 1rem;
}
.BuissnessWrapper {
  font-size: var(--font-16);
  text-align: center;
  margin-top: 3rem;
  font-weight: 500;
  cursor: pointer;
}

.SubmitBtn {
  margin: 1rem;
}
@media only screen and (max-width: 600px) {
  .Wrapper {
    width: 100%;
  }
  .FormHeader {
    font-size: var(--font-26);
  }
  .FormInputName {
    width: 100%;
  }
}
.SendBtnDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
