.Wrapper {
  width: 39rem;
}

.Heading {
  font-weight: 700;
  font-size: var(--font-32);
  color: var(--font-black);
  margin-bottom: 2.4rem;
}

.Astrisk {
  color: var(--primary-red);
}

.FormHeading {
  font-size: var(--font-14);
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.TextFieldWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.PhoneInputWrapper {
  width: 30ch;
}

.InputWrapper p {
  font-size: var(--font-14);
  font-weight: 600;
  color: var(--font-black);
  margin-bottom: 0.6rem;
}

.Button {
  width: 100%;
  background-color: var(--sec-blue);
  justify-content: center;
  color: var(--pure-white);
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  margin-top: 1.5rem;
}

.RecaptchaWrapper {
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
}

.SendBtnDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.PhoneInput {
  padding-left: 0;
}

@media only screen and (max-width: 600px) {
  .Heading {
    font-size: var(--font-24);
  }
}

@media only screen and (max-width: 430px) {
  .Wrapper {
    width: 90%;
  }

  .RecaptchaWrapper {
    margin: 2rem 0;
  }
}
