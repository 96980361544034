.Wrapper {
  /* width: 42rem; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Header {
  font-weight: 700;
  font-size: var(--font-32);
  color: var(--font-black);
  margin-bottom: 1.4rem;
}
.SubText {
  font-size: var(--font-14);
  margin-top: 1.5rem;
}
.Number {
  font-size: var(--font-15);
  font-weight: 500;
}
.Change {
  color: var(--sec-blue);
  margin-left: 0.5rem;
  display: inline;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  text-underline-offset: 0.2rem;
  font-size: var(--font-15);
}
.NoContainer {
  border: 0.1rem solid var(--primary-grey);
  border-radius: 4.8rem;
  width: fit-content;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.FormWrapper {
  padding: 1rem 0;
}
.Input {
  display: grid;
  margin-bottom: 1.4rem;
}
.FormTitle {
  font-size: var(--font-14);
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: start;
}
.FormInputOtp {
  height: 4.8rem;
  border-radius: 0.6rem;
  border: 0.2rem solid #1874d2;
  padding: 1.2rem;
  font-size: var(--font-16);
}

.Resend {
  text-align: start;
  font-size: var(--font-15);
  margin: 1rem 2rem 2rem 0rem;
}
.ResendLink {
  color: var(--sec-blue);
  margin-left: 0.5rem;
  display: inline;
  cursor: pointer;
}
.ResendLink:hover {
  text-decoration: underline;
}
.SendBtn {
  height: 4.8rem;
  justify-content: center;
  background: var(--ter-blue);
  border-radius: 0.6rem;
  color: white;
  border: none;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
}
.ArrowDown {
  margin-left: 1rem;
}
@media only screen and (max-width: 600px) {
  .Header {
    font-size: var(--font-24);
  }
}
