@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: "Inter", sans-serif;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-grey);
}
:root {
  /* Font Sizes */
  --font-12: 1.2rem;
  --font-13: 1.3rem;
  --font-135: 1.35rem;
  --font-14: 1.4rem;
  --font-15: 1.5rem;
  --font-16: 1.6rem;
  --font-17: 1.7rem;
  --font-18: 1.8rem;
  --font-20: 2rem;
  --font-22: 2.2rem;
  --font-24: 2.4rem;
  --font-26: 2.6rem;
  --font-28: 2.8rem;
  --font-30: 3rem;
  --font-32: 3.2rem;
  --font-34: 3.4rem;
  --font-36: 3.6rem;
  --font-38: 3.8rem;
  --font-40: 4rem;
  --font-42: 4.2rem;
  --font-45: 4.5rem;
  --font-48: 4.8rem;
  --font-52: 5.2rem;
  --font-70: 7rem;
  --fontv-4: 4vw;
  --fontv-3: 3vw;
  --fontv-35: 3.5vw;
  /* Colors */
  --pure-white: #ffffff;
  --pure-black: #000000;
  --primary-black: #222222;
  --dark-black: #200e32;
  --sec-black: #444444;
  --ter-black: #888888;
  --font-black: #0f1629;
  --font-grey: #3e424a;
  --primary-grey: #767676;
  --sec-grey: #aaaaaa;
  --ter-grey: #bbbbbb;
  --line-grey: #cccccc;
  --lig-grey: #dddddd;
  --quad-grey: #e0e0e0;
  --pent-grey: #f8f8f8;
  --report-blue: #dde6ff;
  --hex-grey: #fafafa;
  --sept-grey: #dbdcdf;
  --light-grey: #eeeeee;
  --peg-grey: #f3f3f4;
  --light-bg-grey: #fdfdfd;
  --main-grey: #eff2f5;
  --comp-grey: #b4becc;
  --table-head-grey: #f1f5ff;
  --primary-orange: #f36a01;
  --warning-yellow: #ffc107;
  --primary-blue: #222c65;
  --sec-mark-blue: #2e7bff;
  --ter-blue: #0057ff;
  --main-blue: #0141cf;
  --sec-blue: #0052fe;
  --table-hover-blue: #dde7ff;
  --light-blue: #f6f9ff;
  --peg-blue: #e9f0ff;
  --light-sec-blue: #e7efff;
  --light-ter-blue: #1874d2;
  --border-purple: #e1cfff;
  --primary-red: #d70808;
  --sec-red: #f7324c;
  --loss-red: #e75757;
  --light-red: #d65745;
  --request-red: #f7324c;
  --success-green: #79ea86;
  --primary-green: #009229;
  --profit-green: #00a92f;
  --sec-green: #25e006;
  --border-grey: #dddddd;
  --peg-green: #d7fdef;
  --peg-red: #ffeded;
  --peg-green-font: #068e5d;
  --peg-red-font: #be3338;
  --link-blue: #2997f9;
  --blue-bg-light: #edf2ff;
  --blue-bg-dark: #200e32;
  --lig-blue: rgba(41, 151, 249, 0.5);
  --accent-secondary: #fb6f04;
  --absolute-red: #e3112d;

  --light-brown: #777777;

  --transparent-black: rgba(0, 0, 0, 0.1);

  /*custom chip*/
  --reject-red: #fff0f2;

  --accept-green: #c2ffec;
  --accept-greenSolid: #0fba83;
  --pending-yellow: #fff3e8;
  --pending-yellowSolid: #f2994a;

  /* Gradiants */
  --blue-btn-bg: linear-gradient(90.13deg, #005dfc -7.06%, #0041b1 104.45%);
  --prefill-blue-gradient: linear-gradient(
    180deg,
    #1800e2 0%,
    rgba(91, 9, 141, 0.98) 100%
  );
  --prefill-orange-gradient: linear-gradient(180deg, #f33a00 0%, #fa9a29 100%);
  --waiting-list-confirmation-text-gradient: linear-gradient(
    90.13deg,
    #ff0051 -7.06%,
    #bd00ff 104.45%
  );
}

html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-track {
  background-color: rgba(230, 230, 230, 0.2);
}

html::-webkit-scrollbar-thumb {
  background-color: rgba(120, 120, 120, 0.3);
  outline: 1px solid slategrey;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input,
Button,
select,
textarea {
  font-family: "Inter", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style: none;
}

.Font-size-15 {
  font-size: var(--font-15);
}

.Font-size-16 {
  font-size: var(--font-16);
}

.DisabledBtn {
  opacity: 0.5;
  cursor: not-allowed;
}
