.Button {
  border: 1px solid var(--light-grey);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-16);
  color: var(--primary-black);
  text-transform: capitalize;
  gap: 1rem;
  background-color: transparent;
}
.ButtonIcon {
  height: 0.797rem;
  width: 1.275rem;
}

.PrimaryBtn {
  background-color: var(--sec-blue);
  color: var(--pure-white);

  height: 4.8rem;
  width: 100%;
  border-radius: 6px;
  padding: 0.8rem 1.2rem;

  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .Button {
    padding: 0.75rem 1rem;
    font-size: var(--font-14);
    gap: 0.5rem;
  }
  .ButtonIcon {
    height: 1.6rem;
    width: 1.6rem;
  }
  .PrimaryBtn {
    padding: 0.5rem 0.5rem;
    font-size: var(--font-14);
    height: auto;
  }
}
